<template>
	<div class="list-body">
		<div class="search bg-color-fff">
			<Form label-colon inline :label-width="100">
				<FormItem label="设备号">
					<Input v-model="search_form.imei" placeholder="请输入" class="width-200"></Input>
				</FormItem>
				<FormItem label="设备类型">
					<Select class="width-200" v-model="search_form.device_type" clearable placeholder="请选择">
						<Option v-for="(item,index) in device_data" :value="item.value" :key="index">{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="是否绑定">
					<Select class="width-200" v-model="search_form.is_binding" clearable placeholder="请选择">
						<Option :value="1">是</Option>
						<Option :value="0">否</Option>
					</Select>
				</FormItem>
				<FormItem label="所属商家">
					<Input v-model="search_form.store_name" placeholder="请选择所属商家" disabled clearable class="width-200">
					<div slot="append">
						<Button type="primary" style="padding: 0 6px;"
							@click="$refs['sel-store'].open_modal()">选择商家</Button>
					</div>
					</Input>
				</FormItem>
				<FormItem label="用户类型">
					<Select class="width-200" v-model="search_form.user_type" clearable placeholder="请选择">
						<Option v-for="(item, index) in user_type_arr" :value="index" :key="index">{{ item }}</Option>
					</Select>
				</FormItem>
				<FormItem label="用户名">
					<Input class="width-200" v-model="search_form.u_name" placeholder="请输入用户名"></Input>
				</FormItem>
				<FormItem label="用户手机号">
					<Input class="width-200" v-model="search_form.u_phone" placeholder="请输入手机号"></Input>
				</FormItem>
			</Form>
			<div class="search-end">
				<Button style="margin-right: 10px;" @click="reset_form">重置</Button>
				<Button type="primary" @click="init()">查询</Button>
			</div>
		</div>
		<div class="table-body bg-color-fff">
			<div class="tab-oper flex">
				<div>
					<Button style="margin-right: 10px;" type="primary" @click="add_show = true">新增设备</Button>
					<Button type="primary" @click="batch_show = true">批量上传</Button>
				</div>
				<div>
					<Poptip trigger="hover" placement="bottom-end">
						<div slot="content" style="display: flex;width: 128px;justify-content: space-between;flex-wrap: wrap;">
							<div class="content-item">
								<div class="click-btn" @click="build_store">
									<i class="iconfont icon-yonghuguohu- mar-ri-8"></i>
									<span>绑定商家端</span>
								</div>
							</div>
							<div class="content-item">
								<div class="click-btn" @click="delete_multiple">
									<i class="iconfont icon-lajixiang mar-ri-8"></i>
									<span>删除设备</span>
								</div>
							</div>
							<!-- 						<div class="content-item">
								<div class="click-btn">
									<i class="iconfont icon-jiebang mar-ri-8"></i>
									<span>解绑</span>
								</div>
							</div> -->
						</div>
						<Button type="primary" style="width: 160px;">更多操作 <i class="iconfont icon-linecar105"></i></Button>
					</Poptip>
				</div>
			</div>
			<div class="tab-body">
				<Table :data="data" :columns="columns" :loading="loading" @on-selection-change="sel_device_oper">
					<template slot="u_name" slot-scope="{row}">
						<div v-if="row.d_type != 4">
							<span v-if="row.u_id">{{ row.u_name }}</span>
							<Tag v-else color="error">未绑定</Tag>
						</div>
						<Tag v-else color="warning">不可绑定</Tag>
					</template>
					<template slot="user_type" slot-scope="{row}">
						<div>{{ user_type_arr[row.user_type] }}</div>
					</template>
					<template slot="status" slot-scope="{row}">
						<Tag v-if="row.d_type == 0 || row.d_type == 4 || row.d_type == 6 || row.d_type == 7" :color="row.status == 1?'success':'default'">
							{{ row.status == 1?'在线':'离线' }}
						</Tag>
						<div v-else>无</div>
					</template>
					<template slot="operation" slot-scope="{row}">
						<Button style="margin-right: 10px;" type="warning" @click="see_device(row.imei,row.m_id)"
							v-if="row.d_type == 0">查看</Button>
						<Button style="margin-right: 10px;" type="primary" @click="refresh_status(row.imei)"
							v-if="row.d_type == 0 && row.m_id == 1">刷新状态</Button>
						<Button style="margin-right: 10px;" type="primary" @click="$refs.DeviceSetting.open(row.imei)"
							v-if="row.d_type == 0 || row.d_type == 5">设置</Button>
						<Button style="margin-right: 10px;" type="primary"
							@click="toUrl('/monitoring/info', { imei: row.imei, action: 'second' })"
							v-if="row.d_type == 3">实时预览</Button>
						<Button type="error" @click="del_device(row)">删除</Button>
					</template>
				</Table>
			</div>
			<Page style="margin-top: 10px;text-align: right;" v-model="search_form.page" :total="search_form.total"
				:page-size="search_form.pageSize" :page-size-opts="[10,15,20]" show-total show-sizer transfer
				@on-change="page_change" @on-page-size-change="pageSize_change"></Page>
		</div>
		<!-- 新增设备 -->
		<Modal v-model="add_show" :title="build_store_status ? '绑定商家端' : '新增设备'" @on-ok="build_store_status ? build_confirm() : add_confirm()" :loading="add_modal_loading" @on-cancel="add_cancel">
			<Form label-colon :label-width="100" inline>
				<FormItem label="设备号" :required="true" v-if="build_store_status == false">
					<Input class="width-250" v-model="add_data.imei" placeholder="请输入"></Input>
				</FormItem>
				<FormItem label='所属商家'>
					<Input class="width-250" disabled v-model="add_data.store_name">
					<div slot="append">
						<Button type="primary" @click="$refs['sel-store'].open_modal()">选择商家</Button>
					</div>
					</Input>
				</FormItem>
			</Form>
		</Modal>
		<!-- 选择设备 -->
		<SelStore ref="sel-store" @sel-succ="sel_store"></SelStore>
		<!-- 批量上传 -->
		<Modal v-model="batch_show" title="批量上传" @on-cancel="add_cancel">
			<Form label-colon :label-width="100" inline>
				<FormItem label='所属商家'>
					<Input class="width-250" disabled v-model="add_data.store_name">
					<div slot="append">
						<Button type="primary" @click="$refs['sel-store'].open_modal()">选择商家</Button>
					</div>
					</Input>
				</FormItem>
				<FormItem label="上传文件" :required="true">
					<Upload style="float: left;" ref="upload" :action="ApiUrl+'/adm/upload_img'" :on-format-error="format_err" :on-success="upload_succ" :format="['xls','xlsx']" :data="{cloud_id: userInfo.uid}">
						<Button icon="md-cloud-upload" type="primary">文件上传</Button>
					</Upload>
					<Button style="position: absolute; left: 162px;" to="https://api.cloud.jk125.com/uploads/device_error/template.xlsx" >下载模版</Button>
					<div style="clear: both;" class="tips">仅支持上传“xls”或“xlsx”格式文件</div>
				</FormItem>
			</Form>
			<template slot="footer">
				<Button @click="batch_confirm" type="primary" :loading="batch_loading">确 认</Button>
			</template>
		</Modal>
		<Modal v-model="batch_message.show" title="批量上传提示">
			<Alert :type="batch_message.error_count >= 1 ? 'error' : 'success'">
				{{ batch_message.error_count >= 1 ? '添加失败' : '添加成功' }}
				<template slot="desc">
					<div style="margin-bottom: 16px;">成功添加：{{ batch_message.success_count }}个设备，添加失败：{{ batch_message.error_count }}个设备</div>
					<div v-if="batch_message.error_count >= 1">点击下载：<a :href="batch_message.file_url" target="_blank">{{ batch_message.file_name }}</a></div>
				</template>
			</Alert>
		</Modal>
		<DeviceSetting ref="DeviceSetting"></DeviceSetting>
	</div>
</template>

<script>
	var _this;
	import DeviceSetting from '@/components/device/device_setting';
	import SelStore from '@/components/common/SelStore.vue';
	export default {
		components: {
			SelStore, DeviceSetting
		},
		data() {
			return {
				//用户信息
				userInfo: JSON.parse(localStorage.getItem('userInfo')),
				//批量上传模拟态显示
				batch_show: false,
				//批量上传模拟态确认按钮加载状态
				batch_loading: false,
				batch_message: { // 批量上传提示模拟态
					show: false, // 模拟态显示
					error_count: 0, // 失败条数
					success_count: 1, // 成功条数
					file_url: '', // 失败表单下载地址
					file_name: '' // 失败表单名称
				},
				//设备用户类型
				user_type_arr: ['单用户', '用户A', '用户B', '商家自营'],
				//选中要操作的设备数据
				sel_oper_data: [],
				//显示新增模拟态
				add_show: false,
				//显示地图经纬度
				map_show: false,
				//设备搜索地址
				map_addr: '',
				//新增数据
				add_data: {
					imei: null, //设备imei号
					store_id: 0, //商户ID
					store_name: null, //商户名称
					device_type: -1, //设备类型
					manufactor_id: 0, //厂家ID
					store_type: 0, //商户类型
					validate_code: '', // 监控设备验证码
					lng_lat: null, //药柜经纬度
					xlsx: null //xlxs或xls文件
				},
				//查询条件
				search_form: {
					imei: '', //设备imei
					manufactor_id: 0, //厂家ID
					device_type: -1, //设备类型
					is_binding: -1, //是否绑定
					store_name: null, //商家名称
					store_id: 0, //商家ID
					user_type: -1, // 用户类型
					u_name: '', // 用户名
					u_phone: '', // 用户手机号
					page: 1, //当前页码
					pageSize: 10, //每页条数
					total: 0 //总条数
				},
				//新增设备时可选的设备类型
				add_device_data: [],
				//厂家数据
				manufactor_data: [{
					name: '健康125', //厂家名称
					id: 1 //厂家ID
				}],
				//设备类型
				device_data: [],
				//默认设备数据
				default_device_data: [],
				//表格数据
				data: [],
				//表格表头
				columns: [
					{ type: 'selection', width: 60, align: 'center' },
					{ title: '设备号', key: 'imei', minWidth: 200, align: 'center' },
					{ title: '商家', key: 'store_name', align: 'center' },
					{ title: '用户', slot: 'u_name', align: 'center' },
					{ title: '用户类型', slot: 'user_type', align: 'center' },
					{ title: '设备类型', key: 'device_type', align: 'center' },
					{
						title: '电量',
						align: 'center', 
						render: (h, param) => {
							return h('div', param.row.d_type == 0 ? param.row.electric_amount + '%' : '无')
						}
					},
					{ title: '设备状态', slot: 'status', align: 'center' },
					{ title: '操作', slot: 'operation', minWidth: 250, align: 'center' },
				],
				//表格加载
				loading: false,
				// 新增设备模拟态加载状态
				add_modal_loading: true,
				build_store_status: false
			}
		},
		created() {
			_this = this
			this.init()
		},
		methods: {
			//数据初始化
			init() {
				this.loading = true
				this.requestApi('/adm/get_device_list', {
					search_form: this.search_form
				}).then(
					(res) => {
						_this.loading = false
						if (res.code == 200) {
							_this.data = res.data.data.data
							_this.manufactor_data = res.data.manufactor_data
							_this.device_data = res.data.device_data
							_this.default_device_data = JSON.parse(JSON.stringify(res.data.device_data))
							_this.search_form.total = res.data.data.total
						} else {
							_this.alertErr(res.msg)
						}
					}
				)
			},
			//选择商家回调
			sel_store(res) {
				if (this.add_show || this.batch_show) {
					this.add_data.store_name = res.name
					this.add_data.store_id = res.store_id
					this.add_data.store_type = res.type
				} else {
					this.search_form.store_name = res.name
					this.search_form.store_id = res.store_id
				}
			},
			//切换页码
			page_change(page) {
				this.search_form.page = page
				this.init()
			},
			//切换每页条数
			pageSize_change(pageSize) {
				this.search_form.pageSize = pageSize
				this.init()
			},
			//查看设备信息
			see_device(imei, mId) {
				this.toUrl('/device/dtl', {
					imei: imei,
					m_id: mId
				})
			},
			//重置form表单
			reset_form() {
				this.search_form.imei = ''
				this.search_form.manufactor_id = 0
				this.search_form.device_type = -1
				this.search_form.is_binding = -1
				this.search_form.store_name = null
				this.search_form.store_id = 0
				this.search_form.user_type = -1
			},
			//添加设备
			add_confirm() {
				console.log(1111);
				let data = this.add_data
				if (!data.imei) {
					this.edit_add_loading()
					return this.alertErr('请填写设备号')
				// } else if (!data.store_id) {
				// 	this.edit_add_loading()
				// 	return this.alertErr('请选择设备所属商家')
				}
				this.requestApi('/adm/add_device', { data }).then((res) => {
					_this.edit_add_loading()
					if (res.code == 200) {
						_this.alertSucc(res.msg)
						_this.add_cancel()
						_this.add_show = false
						_this.init()
					} else {
						_this.alertErr(res.msg)
					}
				}).catch(() => {
					_this.edit_add_loading()
				})
			},
			//修改模拟态按钮加载状态
			edit_add_loading() {
				this.add_modal_loading = false
				this.$nextTick(() => {
					this.add_modal_loading = true
				})
			},
			//取消模拟态
			add_cancel() {
				// 清除 绑定商家端 的状态
				this.build_store_status = false;
				// 清空上传列表
				this.$refs.upload.clearFiles();
				this.add_data = {
					imei: null, //设备imei号
					store_id: 0, //商户ID
					store_name: null, //商户名称
					device_type: -1, //设备类型
					manufactor_id: 0, //厂家ID
					store_type: 0, //商户类型
					validate_code: '', // 监控设备验证码
					lng_lat: null, //药柜经纬度
					xlsx: null //xlxs或xls文件
				}
				
			},
			//删除设备
			del_device(row) {
				if (row.is_binding) return _this.alertErr('设备已被绑定，无法操作')
				this.$Modal.confirm({
					title: '温馨提示',
					content: '确认删除该设备吗？',
					onOk: () => {
						_this.requestApi('/adm/del_device', {
							id: row.imei
						}).then(
							(res) => {
								if (res.code == 200) {
									_this.alertSucc(res.msg)
								} else {
									_this.alertErr(res.msg)
								}
								_this.init()
							}
						)
					}
				})
			},
			//选中操作设备数据
			sel_device_oper(row) {
				this.sel_oper_data = row
			},
			//批量删除
			delete_multiple() {
				this.testing_problem(1).then(
					() => {
						_this.$Modal.confirm({
							title: '温馨提示',
							content: '确定删除已选择的设备吗？',
							onOk: () => {
								_this.requestApi('/adm/del_dultiple_device', {
									data: _this.sel_oper_data
								}).then(
									(res) => {
										if (res.code == 200) {
											_this.init()
											_this.sel_oper_data = []
											_this.alertSucc(res.msg)
										} else {
											_this.alertErr(res.msg)
										}
									}
								)
							}
						})
					}
				).catch((res) => {
					_this.alertErr(res)
				})
			},
			//判断批量操作需要注意的情况
			testing_problem(type) {
				return new Promise((resolve, reject) => {
					let data = this.sel_oper_data
					//判断是否有需要操作的数据
					if (this.sel_oper_data.length == 0){
						return reject('请选择要操作的设备');
					}else{
						//判断操作数据中是否有已被绑定的用户
						for (let i = 0; i < data.length; i++) {
							if (data[i].is_binding && type == 1) {
								return reject('设备已被绑定无法操作')
							}
							if (data[i].store_id != 0 && type == 2){
								return reject('设备已绑定商家端无法操作')
							}
						}
					}
					return resolve('成功')
				})
			},
			// 刷新设备状态
			refresh_status(imei) {
				this.requestApi('/adm/device_refresh_status', {
					device_id: imei
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.alertSucc(res.msg)
						} else {
							_this.alertErr(res.msg)
						}
						_this.init()
					}
				)
			},
			//文件上传
			upload_succ(res) {
				if (res.status == 1) {
					this.add_data.xlsx = this.ImgUrl + res.pic_url
				} else {
					this.alertErr(res.msg)
				}
			},
			//批量提交设备
			batch_confirm() {
				let data = this.add_data
				this.batch_loading = true;
				if (!data.xlsx) {
					this.batch_loading = false;
					return this.alertErr('请上传文件')
				}
				this.requestApi('/adm/batch_add_device', { data }).then((res) => {
					if (res.code == 200) {
						// 提示模拟态内容
						_this.batch_message.success_count = res.success_count;
						_this.batch_message.error_count = res.error_count;
						_this.batch_message.file_url = res.file_url;
						_this.batch_message.file_name = res.file_name;
						_this.batch_message.show = true;
						// 关闭批量上传模拟态
						_this.add_cancel();
						_this.batch_show = false;
						_this.batch_loading = false;
						_this.init();
					} else {
						_this.batch_loading = false;
						_this.alertErr(res.msg)
					}
				});
			},
			//文件验证失败
			format_err() {
				this.$Message.error({
					content: '文件格式不正确'
				})
			},
			// 绑定商家端
			build_store(){
				this.testing_problem(2).then(() => {
					this.build_store_status = true;
					this.add_show = true;
				}).catch((res) => {
					_this.alertErr(res)
				})
			},
			// 绑定商家端 确认
			build_confirm(){
				var data = {
					imeis: [],
					store_id: this.add_data.store_id
				};
				for (let i in this.sel_oper_data){
					data.imeis.push(this.sel_oper_data[i].imei);
				}
				this.requestApi('/adm/set_device_build_store',{ data: data }).then((res) => {
					if(res.code == 200){
						_this.add_cancel();
						_this.add_show = false;
						_this.sel_oper_data = [];
						_this.alertSucc('绑定成功');
						_this.init();
					}else{
						_this.alertErr(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.amap-sug-result {
		z-index: 2999 !important;
	}

	.width-200 {
		width: 200px;
	}

	.bg-color-fff {
		background-color: #fff;
		padding: 20px;
	}

	.search-end {
		text-align: right;
	}

	.tab-oper {
		justify-content: space-between;
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.table-body {
		margin-top: 20px;
	}

	.tab-body {
		margin-top: 10px;
	}

	.icon-bitian {
		color: red;
	}

	.width-250 {
		width: 250px;
	}

	.content-item {
		padding: 5px;
		width: 100%;
	}

	.click-btn {
		width: 100%;
		cursor: pointer;
	}

	.mar-ri-8 {
		margin-right: 8px;
	}

	.mar-b24 {
		margin-bottom: 24px;
	}

	.w100 {
		width: 100%;
	}

	.tips {
		color: red;
	}

	/deep/ .ivu-modal-body {
		/* height: 100%; */
		/* overflow-y: auto; */
	}

	#map-device {
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 500px;
	}

	.search-form {
		margin-bottom: 10px;
	}

	.addr-input {
		display: inline-block;
		height: 32px;
		line-height: 1.5;
		padding: 4px 7px;
		font-size: 14px;
		border: 1px solid #dcdee2;
		border-radius: 4px;
		color: #515a6e;
		background-color: #fff;
		background-image: none;
		position: relative;
		cursor: text;
		transition: border .2s ease-in-out, background .2s ease-in-out, box-shadow .2s ease-in-out;
	}

	.addr-input:focus {
		border-color: #57a3f3;
		outline: 0;
		box-shadow: 0 0 0 2px rgb(45 140 240 / 20%);
	}
</style>
